import { AuthorizationResponse } from "../contracts/authorization/responses/authorization.contract";

class TokenService {
  private static _instance: TokenService;

  private constructor() {
  }
  
  public static get Instance(): TokenService {
    return this._instance || (this._instance = new this());
  }

  public saveToken(tokenInfo: AuthorizationResponse) {
    localStorage.setItem('tokenInfo', JSON.stringify(tokenInfo));
  }

  public getToken(): AuthorizationResponse | null {
    const tokenStr = localStorage.getItem('tokenInfo');
    if(tokenStr) {
      const token = JSON.parse(tokenStr);
      return token as AuthorizationResponse;
    }
    return null;
  }

  public logOut() {
    localStorage.removeItem('tokenInfo');
    window.location.replace(window.location.origin + "/ihorTest/Authorization");
  }

}
  
export const TokenManager = TokenService.Instance;