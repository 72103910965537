/* eslint-disable react/jsx-no-undef */
import * as React from 'react';

import Logo from '../../assets/images/logo/logo.png';
import UserAvatar from '../../assets/images/avatar.jpg';
import { useTranslation } from "react-i18next";

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Chip from '@mui/material/Chip';


import SearchIcon from '@mui/icons-material/Search';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import DynamicFormOutlinedIcon from '@mui/icons-material/DynamicFormOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const styles = {
  root: {
    backgroundColor: '#333333',
  },
};


function stringToColor(string: string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));


export default function PrimarySearchAppBar() {
  const { t } = useTranslation(['userHome']);

  const userAvatar = React.useState(true);
  
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl1);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl1(null);
  };
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl1}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
      <ListItemIcon>
        <AccountCircleOutlinedIcon fontSize="small" />
      </ListItemIcon>
      {t("Profile", {ns: ['userHome']})}
      </MenuItem>
      <Divider />
      <MenuItem onClick={handleMenuClose}>
      <ListItemIcon>
        <SettingsOutlinedIcon fontSize="small" />
      </ListItemIcon>
      {t("User Settings", {ns: ['userHome']})}
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
      <ListItemIcon>
        <LogoutOutlinedIcon fontSize="small" />
      </ListItemIcon>
      {t("Sign out", {ns: ['userHome']})} 
      </MenuItem>
    </Menu>
  );


  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const isMessageMenuOpen = Boolean(anchorEl2);
  const handleMessageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleMessageMenuClose = () => {
    setAnchorEl2(null);
  };
  const MessageMenu = 'primary-message-menu';
  const renderMessageMenu = (
    <Menu
      anchorEl={anchorEl2}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={MessageMenu}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMessageMenuOpen}
      onClose={handleMessageMenuClose}
    >
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItemButton alignItems="flex-start" onClick={handleMessageMenuClose}>
        <ListItemAvatar>
          <Avatar alt="Ілля Плаксій" {...stringAvatar('Ілля Плаксій')} />
        </ListItemAvatar>
        <ListItemText
          primary="Ілля Плаксій"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              </Typography>
              {"Та роблю я сайт, ти бляха!!!"}
            </React.Fragment>
          }
        />
      </ListItemButton>
      <Divider />
      <ListItemButton alignItems="flex-start" onClick={handleMessageMenuClose}>
        <ListItemAvatar>
        <Avatar alt="Andriy Uzviak" {...stringAvatar('Andriy Uzviak')} />
        </ListItemAvatar>
        <ListItemText
          primary="Andriy Uzviak"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              </Typography>
              {"Зараз візьму Тьорна!"}
            </React.Fragment>
          }
        />
      </ListItemButton>
      <Divider />
      <ListItemButton alignItems="flex-start" onClick={handleMessageMenuClose}>
        <ListItemAvatar>
        <Avatar alt="Ihor Bandura" {...stringAvatar('Ihor Bandura')} />
        </ListItemAvatar>
        <ListItemText
          primary="Ihor Bandura"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              </Typography>
              {'Де мої нюдси? Чекаю в тг!'}
            </React.Fragment>
          }
        />
      </ListItemButton>
      <Divider />
      <ListItemButton alignItems="flex-start" onClick={handleMessageMenuClose}>
        <ListItemAvatar>
        <Avatar alt="Нікітка Капібара" {...stringAvatar('Нікітка Капібара')} />
        </ListItemAvatar>
        <ListItemText
          primary="Нікітка Капібара"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
              </Typography>
              {"Де Кесседі?"}
            </React.Fragment>
          }
        />
      </ListItemButton>
    </List>
    </Menu>

  );


  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const isNotificationMenuOpen = Boolean(anchorEl3);
  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleNotificationMenuClose = () => {
    setAnchorEl3(null);
  };
  const NotificationMenu = 'primary-message-menu';
  const renderNotificationMenu = (
    <Menu
      anchorEl={anchorEl3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={NotificationMenu}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isNotificationMenuOpen}
      onClose={handleNotificationMenuClose}
    >
      <MenuItem onClick={handleNotificationMenuClose}>
        <Chip variant="outlined" label="System" color="warning" icon={<DynamicFormOutlinedIcon />} />
        <Typography sx={{ p: 1 }}>
          В 21:00 не буде світла!
        </Typography>
        <IconButton>
          <HighlightOffOutlinedIcon onClick={handleNotificationMenuClose}/>
        </IconButton>
      </MenuItem>
      <MenuItem onClick={handleNotificationMenuClose}>
        <Chip variant="outlined" label="News" color="success" icon={<GradeOutlinedIcon />} />
        <Typography sx={{ p: 1 }}>
          Вийшла остання частина Атаки Титаннів
        </Typography>
        <IconButton >
          <HighlightOffOutlinedIcon onClick={handleNotificationMenuClose}/>
        </IconButton>
      </MenuItem>
      <MenuItem onClick={handleNotificationMenuClose}>
        <Chip variant="outlined" label="Friends" color="primary" icon={<GroupOutlinedIcon />} />
        <Typography sx={{ p: 1 }}>
          Ви добавили Ismail Mohhamed Al-Lah
        </Typography>
        <IconButton >
          <HighlightOffOutlinedIcon onClick={handleNotificationMenuClose}/>
        </IconButton>
      </MenuItem>
    </Menu>
  );


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={styles.root} position="fixed">
        <Toolbar >
          <img alt='Logotype' src={Logo} className="imageLogo"/>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton 
              size="large" 
              aria-label="show new mails" 
              aria-controls={MessageMenu}
              aria-haspopup="true"
              onClick={handleMessageMenuOpen}
              color="inherit"
            >
              <Badge badgeContent={14} color="error">
                <MailIcon />
              </Badge>
            </IconButton>

            <IconButton
              size="large"
              aria-label="show new notifications"
              aria-controls={NotificationMenu}
              aria-haspopup="true"
              onClick={handleNotificationMenuOpen}
              color="inherit"
            >
              <Badge badgeContent={88} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {userAvatar ?
              <Avatar  alt='Avatar' src={UserAvatar}/>
              :
              <Avatar {...stringAvatar('Roman Gramyak')}/>
              }
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMessageMenu}
      {renderNotificationMenu}
    </Box>
  );
}