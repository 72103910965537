import { Button, Card, CardActions, CardContent, Typography } from '@mui/material';
import bannerImage from '../../assets/images/banner.jpg';
import CustomButton from './testButton.component';

const BannerCard = () => {
  return (
    <Card sx={{ borderRadius: '12px', height: 'auto', display: 'flex', flexDirection: 'column' }}>
      <CardContent style={{ flex: 1 }}>
        <Typography variant="h4" sx={{ textAlign: 'right' }}>
          BANNER NAME
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Lorem ipsum text
        </Typography>
      </CardContent>
      <div style={{ height: '50%', backgroundImage: `url(${bannerImage})`, backgroundSize: 'cover' }} />
      <CardActions sx={{ justifyContent: 'flex-end', pb: 3 }}>
        <CustomButton />
      </CardActions>
    </Card>
  );
};

export default BannerCard