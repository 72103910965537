import { AxiosError } from "axios";
import { ErrorResponse } from "../../api/contracts/common/responses/error.contract";
import { ApiMessageResponseError } from "../../api/errors/api-message-response.error";

/**
 * Thunk Model Base Class -  default settings/error handling for inheriting class
 */
export abstract class BaseThunkModel {
  public isOk: boolean = false;
  public validationErrors: ErrorResponse[] = [];
  public errorMessage: string | null = null;

  public catchError(error: unknown) {
    this.isOk = false;
    if (error instanceof ApiMessageResponseError) {
      this.validationErrors = error.validationErrors;
    } else if (error instanceof AxiosError) {
      this.errorMessage = error.message;
    } else {
      this.errorMessage = "Something went wrong.";
    }
  }
}