import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { Formik, Field, Form, FormikHelpers } from "formik";
import Typography from "@mui/material/Typography";
import {makeStyles} from "tss-react/mui";
import {loginUserAsync, selectAuthorization} from "../../features/user/authSlice";
import {useAppSelector, useAppDispatch} from "../../app/hooks"
import leftSideGirl from './../../assets/images/login/T1.png';
import loginLogo from './../../assets/images/login/Roxy.jpg';
import './../../assets/scss/pages/login.page.scss'
import { AuthorizationResponse } from "../../api/contracts/authorization/responses/authorization.contract";
import { useNavigate } from "react-router-dom";
import { UserApi } from "../../api/services/user.service";

type formValues = {
  email:string,
  password:string,
  rememberMe:boolean
}

const useStyles = makeStyles()({
  'form_field_input' : {
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      backgroundColor: '#fff'
    }
  }
});

const AuthorizationPage: React.FC = () =>{
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {classes} = useStyles();
  const authorizationState = useAppSelector(selectAuthorization);
  const [authorizationMessage, setAuthorizationMessage] = useState<string>("");

  const initialValues: formValues = {
    email:'', 
    password:'', 
    rememberMe:false
  }  

  const validate = ({email, password, rememberMe}: formValues)=>{
    const errors: Record<string, string> = {};
  
    if (!email) {
      errors.email = 'Email is required field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( email)) {
      errors.email = 'Invalid email address';
    }
    
    if (!password) {
      errors.password = 'Password is required field';
    } else if (password.length < 8) {
      errors.password = 'Password is too short';
    } else if (password.length > 20) {
      errors.password = 'Password is too long';
    } else if (!/[a-z]/.test(password)) {
      errors.password = 'Password should contain lower case';
    } else if (!/[A-Z]/.test(password)) {
      errors.password = 'Password should contain upper case';
    } else if (!/[0-9]/.test(password)) {
      errors.password = 'Password should contain a number';
    }
  
    return errors;
  }
  
  const onSubmit = async (data:formValues, {setSubmitting}: FormikHelpers<formValues>) => {
    setSubmitting(true);
    const currentAuthorizationState = await dispatch(loginUserAsync(data));
    if (currentAuthorizationState != null && currentAuthorizationState.payload != null) {
      let authorizationPayload = currentAuthorizationState.payload as AuthorizationResponse;
      if (authorizationPayload.isAuthorized) {
        if(authorizationPayload.isActive)
        {
          setAuthorizationMessage("You're authorized and will be redirected to your home page.");
          const userInfo = await UserApi.GetUserByIdAsync(authorizationPayload.userId == null ? "" : authorizationPayload.userId);
          if (userInfo && userInfo.user && (userInfo.thumbnailImageUrl == null || (userInfo.thumbnailImageUrl && userInfo.thumbnailImageUrl == ""))) {
            navigate("/ihorTest/UploadUserImage");
          } else {
            navigate("/ihorTest/RoomList");
          }
        }
        else
        {
          setAuthorizationMessage("User is not Active. You need to confirm your Email.");
        }
      } else {
        setAuthorizationMessage("Email or Password is wrong.");
      }
    }
    setSubmitting(false);
  }

  return (
    <>
      <Grid container component="main" className="login-page-container">
        <Grid container component="main" className="login-page-container-light">
          <Box component='img' src={leftSideGirl} sx={{position:'fixed', width: '370px', top:'calc(100vh - 370px)', left:'-40px'}}/>
          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            sx={{
              backgroundImage: 'url(' + loginLogo + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid sx={{background: "transparent" }} item xs={12} sm={8} md={6} component={Paper} elevation={6} display={"flex"} justifyContent={"center"} alignItems={"center"} square> 
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" color={"#fff"}>
                Sign In
              </Typography>
              <Formik 
                validateOnChange={true}
                initialValues={initialValues}
                validate={validate}
                onSubmit={onSubmit}>
                {({errors, isSubmitting, touched})=>(
                  <Form>
                    <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Email"
                      name="email"
                      error={!!errors.email && touched.email}
                      helperText={touched.email ? errors.email : ''}
                      autoFocus
                      color="warning" 
                      as={TextField}
                      className={classes.form_field_input} />
                    <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Password"
                      name="password"
                      error={!!errors.password  && touched.password}
                      helperText={touched.password ? errors.password : ''}
                      color="warning" 
                      as={TextField} 
                      className={classes.form_field_input}/>
                    <Field
                      as={FormControlLabel}
                      control={<Checkbox name={"rememberMe"} color="primary"/>} 
                      label="RememberMe" sx={{color:'#fff'}}/>
                    <Box sx={{display: 'flex', justifyContent:'center', mt: 3, mb: 2}}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        sx={{ width: '50%', backgroundColor:'#181818', '&:hover':{backgroundColor:'#fff', color:'#000'}}}>
                          Sign In
                      </Button>
                    </Box>
                    <Typography component="p" color={"#fff"}>
                        {authorizationMessage}
                    </Typography>
                    <Grid marginTop={"15px"} container>
                      <Grid item xs>
                        <Link href="#" variant="body2">
                          Forgot password?
                        </Link>
                      </Grid>
                      <Grid item xs>
                        <Link href="/ihorTest/Registration" variant="body2">
                          {"Don't have an account? Sign Up"}
                        </Link>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default AuthorizationPage;