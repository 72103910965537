import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import './i18n';

import './assets/scss/common/app.scss';

//pages
import { Home } from './pages/test/home.page';
import Login from './pages/login/login';
import Registration from './pages/registration/registration';
import { UserHome } from './pages/user-home/user-home.page';

import AuthorizationPage from './pages/ihorTest/authorization.page';
import RegistrationPage from './pages/ihorTest/registration.page';
import EmailConfirmationPage from './pages/ihorTest/email-confirmation.page';
import { UploadUserImagePage } from './pages/ihorTest/upload-user-image.page';
import { RoomListPage } from './pages/ihorTest/room-list.page';
import RoomPage from './pages/ihorTest/room.page';
import RoomJoinPage from './pages/ihorTest/room-join.page';

//functiional
import { ResendConfirmationCode } from './pages/test/resend-user-confirmation-code.page';
import { GetUserToken } from './pages/test/get-user-token.page';
import { GetJoinedRooms } from './pages/test/get-joined-rooms.page';
import { GetUserInfo } from './pages/test/get-user-info.page';
import { UploadUserImage } from './pages/test/upload-user-image.page';
import SmilePage from './pages/ihorTest/smile.page';

function App() {
  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/resendConfirmationCode" element={<ResendConfirmationCode />} />
          <Route path="/getUserToken" element={<GetUserToken />} />
          <Route path="/getJoinedRooms" element={<GetJoinedRooms />} />
          <Route path="/getUserInfo" element={<GetUserInfo />} />
          <Route path="/uploadUserImage" element={<UploadUserImage />} />
          <Route path="/userHome" element={<UserHome />} />

          <Route path="/ihorTest/Authorization" element={<AuthorizationPage />} />
          <Route path="/ihorTest/Registration" element={<RegistrationPage />} />
          <Route path="/Confirmation" element={<EmailConfirmationPage />} />
          <Route path="/ihorTest/UploadUserImage" element={<UploadUserImagePage />} />
          <Route path="/ihorTest/RoomList" element={<RoomListPage />} />
          <Route path="/ihorTest/Room" element={<RoomPage/>} />
          <Route path="/ihorTest/Room/Join" element={<RoomJoinPage/>} />
          <Route path="/ihorTest/Smile" element={<SmilePage/>} />
          
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
