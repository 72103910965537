import React, { Ref, forwardRef, useImperativeHandle } from "react";
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

export interface InformationSnackbarComponentProps {
  severity?: AlertColor | undefined;
}

export interface InformationSnackbarComponentRefObject {
  OpenSnackBar: (title: string) => void
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const InformationSnackbarComponent = forwardRef((props: InformationSnackbarComponentProps, ref: Ref<InformationSnackbarComponentRefObject>) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [title, setTitle] = React.useState("");

  useImperativeHandle(ref, () => ({ OpenSnackBar }));

  const OpenSnackBar = (title: string) => {
    setOpenSnackbar(true);
    setTitle(title);
  }

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={props.severity} sx={{ width: '100%' }}>
          {title}
        </Alert>
      </Snackbar>
    </>);
});