import * as React from 'react';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';

type FilmCardProps = {
  title: string;
  rating: number;
  onCreateRoomClick: () => void;
  onWatchClick: () => void;
};

const FilmCard: React.FC<FilmCardProps> = ({ title, rating, onCreateRoomClick, onWatchClick }) => {


  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Rating: {rating}
        </Typography>
      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" onClick={onCreateRoomClick}>
          Create Room
        </Button>
        <Button variant="contained" onClick={onWatchClick}>
          Watch
        </Button>
      </CardActions>
    </Card>
  );
};

export default FilmCard;