import { PagedResponse } from "../contracts/common/responses/pagedResponse.contract";
import { SearchMovieRequest } from "../contracts/movie/requests/search-movie.contract";
import { SearchMovieResponse } from "../contracts/movie/responses/search-movie.contract";
import { RoomResponse } from "../contracts/room/responses/room.contract";
import { BaseService } from "./common/base.service";

class MovieService extends BaseService {
  private static _instance: MovieService;
  private static _controller: string = 'Movie';
  
  private constructor(name: string) {
    super(name);
  }
  
  public static get Instance(): MovieService {
    return this._instance || (this._instance = new this(this._controller));
  }

  public async SearchAsync(request: SearchMovieRequest): Promise<PagedResponse<SearchMovieResponse>> {
    if (!request.page || request.page < 1) {
      request.page = 1;
    }

    if (!request.itemsPerPage || request.itemsPerPage > 10 || request.itemsPerPage < 0) {
      request.itemsPerPage = 10;
    }

    let query = `Search?page=${request.page}&itemsPerPage=${request.itemsPerPage}&orderId=${request.orderId}&orderDirectionId=${request.orderDirectionId}`;

    if (request.name) {
      request.name = encodeURI(request.name);
      query += `&name=${request.name}`;
    };

    if (request.movieTypeId) {
      query += `&movieTypeId=${request.movieTypeId}`;
    }

    if (request.movieMainCategoryId) {
      query += `&movieMainCategoryId=${request.movieMainCategoryId}`;
    }

    if (request.genreId) {
      query += `&genreId=${request.genreId}`;
    }

    if (request.countryId) {
      query += `&countryId=${request.countryId}`;
    }

    const { data } = await this.getAsync<PagedResponse<RoomResponse>>(query);
    return data;
  }
}

export const MovieApi = MovieService.Instance;