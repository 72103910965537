
import { UploadFileResponse } from "../contracts/file/responses/upload-file.contract";
import { BaseService } from "./common/base.service";

class FileService extends BaseService {
  private static _instance: FileService;
  private static _controller: string = 'File';
  
  private constructor(name: string) {
    super(name);
  }
  
  public static get Instance(): FileService {
    return this._instance || (this._instance = new this(this._controller));
  }

  public async UploadUserThumbnailImageAsync(file: File, onUploadProgress: any): Promise<UploadFileResponse> {
    let formData = new FormData();
    formData.append("file", file);
    const { data } = await this.postFileAsync<UploadFileResponse>('UpdateUserThumbnailImage', formData, { onUploadProgress: onUploadProgress });
    return data;
  }
}

export const FileApi = FileService.Instance;