import { FileErrorContainerResponse } from "../contracts/common/responses/file-error-container.contract";

export class ApiUploadFileResponseError extends Error {
  public error: FileErrorContainerResponse | null = null;

  constructor(msg: string, error: FileErrorContainerResponse) {
    super(msg);
    this.name = 'ApiUploadFileResponseError';
    this.message = msg;
    this.error = error;
    
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiUploadFileResponseError.prototype);
  }
}