import './../../assets/scss/pages/ihortest.pages.scss'
import React, { useEffect, useRef, useState } from "react";
import { Container, Nav } from "react-bootstrap";
//components
import { NavBar } from '../../components/test/navbar.component';
import { Footer } from '../../components/test/footer.component';
import { useNavigate } from "react-router-dom";
import { PagedResponse } from "../../api/contracts/common/responses/pagedResponse.contract";
import { RoomResponse } from "../../api/contracts/room/responses/room.contract";
import { RoomApi } from "../../api/services/room.service";
import { CreateRoomRequest } from "../../api/contracts/room/requests/create-room.contract";
import { ApiMessageResponseError } from "../../api/errors/api-message-response.error";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { RoomListGridComponent } from '../../components/ihorTest/room-list-grid.component';
import { InformationSnackbarComponent, InformationSnackbarComponentRefObject } from '../../components/ihorTest/information-snackbar.component';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const RoomListPage = () => {
  const navigate = useNavigate();
  const roomNameTxt = useRef<HTMLInputElement>(null);
  const roomTypeDdl = useRef<HTMLSelectElement>(null);
  const successSnackbarRef = useRef<InformationSnackbarComponentRefObject>(null);
  const warningSnackbarRef = useRef<InformationSnackbarComponentRefObject>(null);
  const errorSnackbarRef = useRef<InformationSnackbarComponentRefObject>(null);

  const [ createdRoomsResponse, setCreatedRoomsResponse ] = useState<PagedResponse<RoomResponse>>();
  const [ createdRoomsCurrentPage, setCreatedRoomsCurrentPage ] = useState<number>(1);
  const [ refreshCreatedRooms, setRefreshCreatedRooms ] = useState<boolean>(false);
  const createdRoomsItemsPerPage = 10;

  const [ joinedRoomsResponse, setJoinedRoomsResponse ] = useState<PagedResponse<RoomResponse>>();
  const [ joinedRoomsCurrentPage, setJoinedRoomsCurrentPage ] = useState<number>(1);
  const [ refreshJoinedRooms, setRefreshJoinedRooms ] = useState<boolean>(false);
  const joinedRoomsItemsPerPage = 10;

  const [ publicRoomsResponse, setPublicRoomsResponse ] = useState<PagedResponse<RoomResponse>>();
  const [ publicRoomsCurrentPage, setPublicRoomsCurrentPage ] = useState<number>(1);
  const [ refreshPublicRooms, setRefreshPublicRooms ] = useState<boolean>(false);
  const publicRoomsItemsPerPage = 10;

  var isLoaded = false;

  useEffect(() => {
    if(!isLoaded) {
      isLoaded = true;
      fetchInitialData().catch(console.error);
    }
  }, []);

  useEffect(() => {
    fetchCreatedRoomsData().catch(console.error);
  }, [createdRoomsCurrentPage, refreshCreatedRooms]);

  useEffect(() => {
    fetchJoinedRoomsData().catch(console.error);
  }, [joinedRoomsCurrentPage, refreshJoinedRooms]);
  
  useEffect(() => {
    fetchPublicRoomsData().catch(console.error);
  }, [publicRoomsCurrentPage, refreshPublicRooms]);

  const fetchInitialData = async () => {
    let createdRoomsResponse = await RoomApi.GetCreatedRoomsAsync(1, createdRoomsItemsPerPage);
    let joinedRoomsResponse = await RoomApi.GetJoinedRoomsAsync(1, joinedRoomsItemsPerPage);
    let publicRoomsReponse = await RoomApi.GetPublicRoomsAsync(1, publicRoomsItemsPerPage);
    setCreatedRoomsResponse(createdRoomsResponse);
    setJoinedRoomsResponse(joinedRoomsResponse);
    setPublicRoomsResponse(publicRoomsReponse);
  }

  const fetchCreatedRoomsData = async () => {
    let createdRoomsResponse = await RoomApi.GetCreatedRoomsAsync(createdRoomsCurrentPage, createdRoomsItemsPerPage);
    setCreatedRoomsResponse(createdRoomsResponse);
  }

  const onChangeCreatedRoomsPage = (page: number) => {
    setCreatedRoomsCurrentPage(page);
  }

  const fetchJoinedRoomsData = async () => {
    let joinedRoomsResponse = await RoomApi.GetJoinedRoomsAsync(joinedRoomsCurrentPage, joinedRoomsItemsPerPage);
    setJoinedRoomsResponse(joinedRoomsResponse);
  }

  const onChangeJoinedRoomsPage = (page: number) => {
    setJoinedRoomsCurrentPage(page);
  }

  const fetchPublicRoomsData = async () => {
    let publicRoomsResponse = await RoomApi.GetPublicRoomsAsync(publicRoomsCurrentPage, publicRoomsItemsPerPage);
    setPublicRoomsResponse(publicRoomsResponse);
  }

  const onChangePublicRoomsPage = (page: number) => {
    setPublicRoomsCurrentPage(page);
  }

  const onDeleteRoom = async (roomId: string) => {
    await RoomApi.DeleteRoomAsync(roomId);
    setRefreshCreatedRooms(!refreshCreatedRooms);
    setRefreshJoinedRooms(!refreshJoinedRooms);
    setRefreshPublicRooms(!refreshPublicRooms);
    warningSnackbarRef.current?.OpenSnackBar("Your room was removed.");
  }

  const onOpenRoom = async (roomId: string) => {
    navigate("/ihorTest/Room?roomid="+roomId);
  }

  const onJoinRoom = async (roomId: string) => {
    try {
      await RoomApi.JoinRoomAsync(roomId);
      successSnackbarRef.current?.OpenSnackBar("You joined room.");
    } catch(error) {
      if (error instanceof ApiMessageResponseError) {
        console.log(error.validationErrors[0].message);
        if(error.validationErrors != null && error.validationErrors.length > 0 
          && error.validationErrors[0].message == "User already in this room.") {
            errorSnackbarRef.current?.OpenSnackBar("You already joined this room.");
        }
      }
    }
    setRefreshCreatedRooms(!refreshCreatedRooms);
    setRefreshJoinedRooms(!refreshJoinedRooms);
    setRefreshPublicRooms(!refreshPublicRooms);
  }

  const onLeaveRoom = async (roomId: string) => {
    await RoomApi.LeaveRoomAsync(roomId);
    setRefreshCreatedRooms(!refreshCreatedRooms);
    setRefreshJoinedRooms(!refreshJoinedRooms);
    setRefreshPublicRooms(!refreshPublicRooms);
    successSnackbarRef.current?.OpenSnackBar("You left room.");
  }

  const onCreateNewRoom = async () => {
    if (roomTypeDdl && roomTypeDdl.current && roomTypeDdl.current.value
      && roomNameTxt && roomNameTxt.current && roomNameTxt.current.value) {
      let roomType = roomTypeDdl.current.value;
      let roomName = roomNameTxt.current.value;
      let createRoomRequest = new CreateRoomRequest();
      createRoomRequest.name = roomName;
      createRoomRequest.roomType = roomType;
      let response = await RoomApi.CreateRoomAsync(createRoomRequest);
      setRefreshCreatedRooms(!refreshCreatedRooms);
      setRefreshJoinedRooms(!refreshJoinedRooms);
      setRefreshPublicRooms(!refreshPublicRooms);
      successSnackbarRef.current?.OpenSnackBar("Your room was created.");
    }
  }

  return (
    <>
      <div className="home-body">
        {/* <NavBar/> */}
        <div style={{ marginBottom: 30 }}>
          тут мав бути якийсь навбар
        </div>
        
        <Container>
          <div className="room-list-container-page">
            <div>
              <h2>Create Room</h2>
            </div>
            <div className='create-room-form-container'>
              <div>
                <label htmlFor="roomNameTxt">Room Name:</label>
                <input 
                  type="text"
                  id="roomNameTxt"
                  name="roomNameTxt"
                  ref={roomNameTxt} />
              </div>
              <div>
                <label htmlFor="roomTypeDdl">Room Type:</label>
                <select id="roomTypeDdl" name="roomTypeDdl" ref={roomTypeDdl}>
                  <option value="0">Public</option>
                  <option value="1">Private</option>
                </select>
              </div>
              <div className="create-room-btn" onClick={() => onCreateNewRoom()}>
                CREATE ROOM
              </div>
            </div>
          </div>

          <div className='grid-items-wrapper'>
            <RoomListGridComponent 
              roomResponse={createdRoomsResponse}
              onBtnClicked={onDeleteRoom}
              buttonTitle='DELETE'
              onOpenRoom={onOpenRoom}
              onChangeRoomsPage={onChangeCreatedRoomsPage}
              title='List of your Hosted Rooms:'
              noItemsMessage='No Hosted Rooms.'
            />

            <RoomListGridComponent 
              roomResponse={joinedRoomsResponse}
              onBtnClicked={onLeaveRoom}
              buttonTitle='LEAVE'
              onOpenRoom={onOpenRoom}
              onChangeRoomsPage={onChangeJoinedRoomsPage}
              title='List of rooms in which you are a guest:'
              noItemsMessage='No Joined Rooms.'
            />

            <RoomListGridComponent 
              roomResponse={publicRoomsResponse}
              onBtnClicked={onJoinRoom}
              buttonTitle='JOIN'
              onOpenRoom={onOpenRoom}
              onChangeRoomsPage={onChangePublicRoomsPage}
              title='List of public Rooms:'
              noItemsMessage='No Public Rooms.'
            />
          </div>
        </Container>
        <Footer/>
      </div>

      <InformationSnackbarComponent severity="success" ref={successSnackbarRef} />
      <InformationSnackbarComponent severity="warning" ref={warningSnackbarRef} />
      <InformationSnackbarComponent severity="error" ref={errorSnackbarRef} />
    </>
  );
};