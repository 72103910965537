import axios, { AxiosError, AxiosHeaders, AxiosRequestConfig, AxiosResponse, type AxiosInstance } from 'axios';
import { API } from '../../../config/endpoints'
import { RefreshTokenRequest } from '../../contracts/authorization/requests/refresh-token.contract';
import { ErrorResponse } from '../../contracts/common/responses/error.contract';
import { FileErrorContainerResponse } from '../../contracts/common/responses/file-error-container.contract';
import { RequestType } from '../../contracts/enums/requestType.enum';
import { ApiEntityTooLargeError } from '../../errors/api-entity-too-large.error';
import { ApiMessageResponseError } from '../../errors/api-message-response.error';
import { ApiUnauthorizedError } from '../../errors/api-unauthorized.error';
import { ApiUploadFileResponseError } from '../../errors/api-upload-file-response.error';
import { AuthApi } from '../auth.service';
import { TokenManager } from '../token.service';

/**
 * Service API base class - configures default settings/error handling for inheriting class
 */
export abstract class BaseService {
  protected readonly $http: AxiosInstance;

  protected constructor(controller: string, timeout: number = 50000) {
    this.$http = axios.create({
      timeout,
      baseURL: `${API.BaseUrl}${controller}`,
      validateStatus:  function (status) {
        return status < 500; // Resolve only if the status code is less than 500(Server error)
      }
    });
  }

  private prepareConfig<D = any>(config?: AxiosRequestConfig<D>): AxiosRequestConfig<D> | undefined {
    if (config) {
      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }
    } else {
      config = {
        headers: new AxiosHeaders()
      }
    }
    return config;
  }

  private fileUploadConfig<D = any>(config?: AxiosRequestConfig<D>): AxiosRequestConfig<D> | undefined {
    if(config && config.headers) {
      (config.headers as AxiosHeaders).set("Content-Type", "multipart/form-data");
    }
    return config;
  }

  private authorizeConfig<D = any>(config?: AxiosRequestConfig<D>): AxiosRequestConfig<D> | undefined {
    const token = TokenManager.getToken();
    if(token && token.tokenType && token.token && config && config.headers) {
      config.headers = (config.headers as AxiosHeaders);
      if(config.headers.has("Authorization")) {
        config.headers.delete("Authorization");
      }
      config.headers.set("Authorization", `${token.tokenType} ${token.token}`);
    }
    return config;
  }

  private async tryToAuthorizeUser(): Promise<boolean> {
    const oldToken = TokenManager.getToken();
    if (oldToken && oldToken.userEmail && oldToken.refreshToken) {
      const refreshTokenRequest: RefreshTokenRequest = new RefreshTokenRequest();
      refreshTokenRequest.email = oldToken.userEmail;
      refreshTokenRequest.refreshToken = oldToken.refreshToken;
      try {
        const newToken = await AuthApi.refreshToken(refreshTokenRequest);
        if (newToken != null && newToken.isAuthorized === true && newToken.isActive === true && newToken.token && newToken.refreshToken) {
          return true;
        }
        return false;
      } catch(error) {
        return false;
      }
    } else {
      return false;
    }
  }

  private async isNeedToResendRequest<T = any>(response: AxiosResponse<T>): Promise<boolean> {
    if (response.status === 401) {
      const isUserRefreshed: boolean = await this.tryToAuthorizeUser();
      if (!isUserRefreshed) {
        TokenManager.logOut();
        throw new ApiUnauthorizedError("User is not Authorized.");
      }
      return true;
    }
    return false;
  }

  private async checkResponseForErrorsAsync<T = any>(response: AxiosResponse<T>, isFile: boolean = false) {
    if (response.status !== 200) {
      if (response.status === 400 && response.data != null) {
        if (isFile) {
          if (FileErrorContainerResponse.isFileErrorContainerResponse(response.data)) {
            throw new ApiUploadFileResponseError("File Upload Error", response.data as FileErrorContainerResponse);
          }
        }
        const errors = ErrorResponse.convertArr(response.data);
        if (errors != null) {
          throw new ApiMessageResponseError("Validation Error", errors);
        }
        throw new AxiosError("Bad Request", response.statusText, response.config, response.request, response);
      } else if (response.status === 413) {
        throw new ApiEntityTooLargeError("data in request is too large.");
      } else {
        throw new AxiosError("Unknown response", response.statusText, response.config, response.request, response);
      }
    }
  }

  protected async makeRequestAsync<T = any, D = any>(requestType: RequestType, url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    if (requestType === RequestType.POST) {
      return await this.$http.post(url, data, config);
    } else if (requestType === RequestType.PUT) {
      return await this.$http.put(url, data, config);
    } else if (requestType === RequestType.GET) {
      return await this.$http.get(`${url}`, config);
    } else {
      return await this.$http.delete(url, config);
    }
  }

  protected async makeRequestWithConfigsAsync<T = any, D = any>(requestType: RequestType, url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    config = this.authorizeConfig(config);

    let response = await this.makeRequestAsync(requestType, url, data, config);

    const isNeedToResendRequest = await this.isNeedToResendRequest(response);
    if (isNeedToResendRequest) {
      config = this.authorizeConfig(config);
      response = await this.makeRequestAsync(requestType, url, data, config);
    }

    await this.checkResponseForErrorsAsync<T>(response);

    return response;
  }

  protected async postAsync<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    config = this.prepareConfig(config);
    return this.makeRequestWithConfigsAsync(RequestType.POST, url, data, config);
  }

  protected async putAsync<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    config = this.prepareConfig(config);
    return this.makeRequestWithConfigsAsync(RequestType.PUT, url, data, config);
  }

  protected async getAsync<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    config = this.prepareConfig(config);
    return this.makeRequestWithConfigsAsync(RequestType.GET, url, null, config);
  }

  protected async deleteAsync<T = any, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    config = this.prepareConfig(config);
    return this.makeRequestWithConfigsAsync(RequestType.DELETE, url, null, config);
  }

  protected async postFileAsync<T = any, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<AxiosResponse<T>> {
    config = this.prepareConfig(config);
    config = this.fileUploadConfig(config);
    return this.makeRequestWithConfigsAsync(RequestType.POST, url, data, config);
  }
}