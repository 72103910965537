import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AuthApi } from './../../api/services/auth.service';
import { UserApi } from './../../api/services/user.service';
import { AuthorizationRequest } from './../../api/contracts/authorization/requests/authorization.contract';
import { AuthorizationResponse } from "../../api/contracts/authorization/responses/authorization.contract";
import { StateStatus } from "../common/state-status.enum";
import { RegisterUserRequest } from "../../api/contracts/user/requests/register-user.contract";
import { act } from "react-dom/test-utils";
import { ResponseModel } from "../models/ResponseModel";

type AuthState = {
	authorizationResponse?: AuthorizationResponse | null,
	registerResponse?: {} | null,
	status: StateStatus,
	message?: string | null,
	registerMessage?: string | null
}

const initialState: AuthState = {
	authorizationResponse: null,
	status: StateStatus.notInitialized,
	message: null
}

export const loginUserAsync = createAsyncThunk(
	'auth/fetchLogin',
	async (data: AuthorizationRequest) => {
		try {
			const response = await AuthApi.loginAsync(data);
			return response;
		} catch (error) {
			const response = new AuthorizationResponse();
			response.message = "Server Internal Error.";
			response.isActive = false;
			response.isAuthorized = false;
			return response;
		}
	}
)

export const registerUserAsync = createAsyncThunk(
	'auth/fetchRegister',
	async (data: RegisterUserRequest) => {
		try {
			const response = await UserApi.registerUserAsync(data);
			let result = new ResponseModel();
			result.isOk = true;
			result.message = "Ok";
			result.response = response;
			return result;
		} catch (error) {
			let result = new ResponseModel();
			result.isOk = false;
			result.message = "Server Internal Error.";
			result.response = null;
			return result;
		}
	}
)

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUserAsync.pending, (state) => {
				state.status = StateStatus.loading;
			})
			.addCase(loginUserAsync.fulfilled, (state, action) => {
				if (action.payload != null) {
					state.authorizationResponse = action.payload;
					if (action.payload.isAuthorized === true && action.payload.isActive === true) {
						state.status = StateStatus.successfull;
						state.message = "User Authorized.";
					} else {
						state.message = action.payload.message ?? "No message.";
						state.status = StateStatus.validationError;
					}
				} else {
					state.message = "Server Internal Error.";
					state.status = StateStatus.error;
				}
			})
			.addCase(loginUserAsync.rejected, (state, action) => {
				state.message = "Server Internal Error.";
				state.status = StateStatus.error;
			});
		builder
			.addCase(registerUserAsync.fulfilled, (state, action) => {
				if (action.payload != null) {
					state.registerResponse = action.payload;
				}
			})
			.addCase(registerUserAsync.rejected, (state, action) => {
				console.log(action.payload);
			})
	}
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthorization = (state: RootState) => state.auth;

export default authSlice.reducer;