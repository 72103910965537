import { PagedResponse } from "../contracts/common/responses/pagedResponse.contract";
import { CreateRoomRequest } from "../contracts/room/requests/create-room.contract";
import { UpdateRoomRequest } from "../contracts/room/requests/update-room.contract";
import { RoomDetailedResponse } from "../contracts/room/responses/room-detailed.contract";
import { RoomResponse } from "../contracts/room/responses/room.contract";
import { BaseService } from "./common/base.service";

class RoomService extends BaseService {
  private static _instance: RoomService;
  private static _controller: string = 'Room';
  
  private constructor(name: string) {
    super(name);
  }
  
  public static get Instance(): RoomService {
    return this._instance || (this._instance = new this(this._controller));
  }
  
  public async CreateRoomAsync(requestData: CreateRoomRequest): Promise<RoomResponse> {
    const { data } = await this.postAsync<RoomResponse>('', requestData);
    return data;
  }
  
  public async UpdateRoomAsync(requestData: UpdateRoomRequest) {
    await this.putAsync('', requestData);
  }

  public async DeleteRoomAsync(roomId: string) {
    await this.deleteAsync(roomId);
  }

  public async JoinRoomAsync(roomId: string, secretToken: string | null = null) {
    let query = `${roomId}/Join`;

    if (secretToken) {
      query += `?secretToken=${encodeURI(secretToken)}`;
    }

    await this.putAsync(query);
  }

  public async LeaveRoomAsync(roomId: string) {
    await this.putAsync(`${roomId}/Leave`);
  }

  public async GetRoomByIdAsync(roomId: string): Promise<RoomDetailedResponse> {
    const { data } = await this.getAsync<RoomDetailedResponse>(roomId);
    return data;
  }

  public async GetPublicRoomsAsync(page: number = 1, itemsPerPage: number = 10, name: string = ""): Promise<PagedResponse<RoomResponse>> {
    if (page < 1) {
      page = 1;
    }
    name = encodeURI(name);
    const { data } = await this.getAsync<PagedResponse<RoomResponse>>(`Public?page=${page}&itemsPerPage=${itemsPerPage}&name=${name}`);
    return data;
  }

  public async GetCreatedRoomsAsync(page: number = 1, itemsPerPage: number = 10, name: string = ""): Promise<PagedResponse<RoomResponse>> {
    if (page < 1) {
      page = 1;
    }
    name = encodeURI(name);
    const { data } = await this.getAsync<PagedResponse<RoomResponse>>(`Created?page=${page}&itemsPerPage=${itemsPerPage}&name=${name}`);
    return data;
  }

  public async GetJoinedRoomsAsync(page: number = 1, itemsPerPage: number = 10, name: string = ""): Promise<PagedResponse<RoomResponse>> {
    if (page < 1) {
      page = 1;
    }
    name = encodeURI(name);
    const { data } = await this.getAsync<PagedResponse<RoomResponse>>(`Joined?page=${page}&itemsPerPage=${itemsPerPage}&name=${name}`);
    return data;
  }
}

export const RoomApi = RoomService.Instance;