import { FileErrorResponse } from "./file-error.contract";

export class FileErrorContainerResponse {
  public type?: string;
  public title?: string;
  public status?: number;
  public traceId?: string;
  public errors?: FileErrorResponse;

  public static isFileErrorContainerResponse(input: any): input is FileErrorContainerResponse {
    const model: FileErrorContainerResponse = input as FileErrorContainerResponse;
    if(model != null && model.errors !== undefined) {
        return true;
    }
    return false;
}
};