import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { TokenManager } from "../../api/services/token.service";
//components
import { NavBar } from '../../components/test/navbar.component';
import { Footer } from '../../components/test/footer.component';

export const GetUserToken = () => {
  const [message, setMessage] = useState<string>("");

  function getUserToken() {
    const token = TokenManager.getToken();
    if(token) {
      setMessage(JSON.stringify(token));
    } else {
      setMessage("Not Authorized");
    }
  }

  return (
    <div className="home-body">
      <NavBar />
      <Container>
        <div className='test-button-wrapper'>
          <button className='test-button' onClick={getUserToken}>getUserToken</button>
        </div>
        <div className="result">
          {message}
        </div>
      </Container>
      <Footer />
    </div>
      
  );
};