import { MovieMainCategory } from "../../enums/movieMainCategory.enum";
import { MovieOrderType } from "../../enums/movieOrderType.enum";
import { MovieType } from "../../enums/movieType.enum";
import { OrderDirection } from "../../enums/orderDirection.enum";

export class SearchMovieRequest {
  public movieTypeId?: MovieType;
  public movieMainCategoryId?: MovieMainCategory;
  public page?: number;
  public itemsPerPage?: number;
  public name?: string;
  public genreId?: number;
  public countryId?: number;
  public orderId: MovieOrderType = MovieOrderType.CreatedDate;
  public orderDirectionId: OrderDirection = OrderDirection.Descending;
};