import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import './../../assets/scss/pages/login.page.scss'
import { RoomApi } from "../../api/services/room.service";
import { RoomDetailedResponse } from "../../api/contracts/room/responses/room-detailed.contract";
import { SearchMovieComponent, SearchMovieComponentRefObject } from "../../components/ihorTest/search-movie.component";

const RoomPage: React.FC = () =>{
  const [searchParams] = useSearchParams();
  const [roomId, setRoomId] = useState<string>();
  const [roomData, setRoomData] = useState<RoomDetailedResponse>();
  const searchMovieRef = useRef<SearchMovieComponentRefObject>(null);
  const navigate = useNavigate();
  var isLoaded = false;

  useEffect(() => {
    if (!isLoaded) {
      isLoaded = true;
      
      let roomIdTemp = searchParams.get("roomid");
      if (roomIdTemp)
      {
        setRoomId(roomIdTemp);
      }
    }
  }, []);

  useEffect(() => {
    if(roomId) {
      fetchRoomData(roomId).catch(console.error);
    }
  }, [roomId]);

  const fetchRoomData = async (roomId: string) => {
    let room = await RoomApi.GetRoomByIdAsync(roomId);
    setRoomData(room);
  }

  const ToggleSearchMovie = () => {
    searchMovieRef.current?.TogleOpen();
  }

  return (
    <>
      <div className="open-movie-catalog-btn-wrapper">
        <div className="open-movie-catalog-btn" onClick={() => ToggleSearchMovie()}>
          Open Movie Catalog
        </div>
      </div>

      {roomData && roomData.room && roomData.secretToken ?
      <>
        <div>
          <div>
            send this link to your friends to invite them to your room:
          </div>
          <div>
            {window.location.origin}/ihorTest/Room/Join?roomId={roomData.room.id}&secretToken={encodeURI(roomData.secretToken)}
          </div>
        </div>
      </>
      :<></>
      }

      <div className="users-list-wrapper">
        <p>Users in this room:</p>
        <div className="users-list">
          {roomData != null && roomData.usersInRoom != null ? 
          <>
            {roomData.usersInRoom.map((e, i) => { return(
              <div key={i} className="users-list-item">
                <img src={e.thumbnailImageUrl} />
                <p>{e.name}</p>
              </div>
            );})}
          </> 
          : <></>}
        </div>
      </div>
      <div className="playlist-wrapper">
        <p>Playlist:</p>
        <div className="playlist-list">
          {roomData && roomData.playlistMovies ? 
          <>
            {roomData.playlistMovies.map((movie, movieIndex) => { return (
              <div key={movieIndex} className="playlist-list-movie-item">
                <div className="movie-info">
                  <img src={movie.movie?.imdbCoverUrl} />
                  <div className="playlist-movie-name"> 
                    {movie.name}
                  </div>
                </div>

                <div className="playlist-movie-episode-list">
                  {movie.playlistMovieVideoDtOs ?
                  <>
                    {movie.playlistMovieVideoDtOs.map((movieVideo, movieVideoIndex) => { return (
                      <div key={movieVideoIndex} className="episode-list-item">
                        <div className="season-property">
                          <div className="season-property-name">
                            Season:
                          </div>
                          <div className="season-property-value">
                            {movieVideo.season}
                          </div>
                        </div>
                        <div className="season-property">
                          <div className="season-property-name">
                            Episode:
                          </div>
                          <div className="season-property-value">
                            {movieVideo.episode}
                          </div>
                        </div>
                        <div className="quality-list">
                          {movieVideo.playlistMovieVideoQualityDtos ?
                          <>
                            {movieVideo.playlistMovieVideoQualityDtos.map((quality, qualityIndex) => { return (
                              <div key={qualityIndex} className="quality-list-item">
                                <a href={quality.movieUrl}>{quality.qualityId}</a>
                              </div>
                            );})}
                          </>
                          : <> No Qualities</>}
                        </div>
                      </div>
                    );})}
                  </> 
                  : <><div>No Episodes</div></>}
                </div>
              </div>
            );})}
          </> 
          : <> No Movies</>}
        </div>
      </div>
      <SearchMovieComponent ref={searchMovieRef}></SearchMovieComponent>
    </>
  );
}

export default RoomPage;