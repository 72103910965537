import { AxiosError } from "axios";
import React, { useState } from "react";
import { Container, Nav } from "react-bootstrap";
import { ApiEntityTooLargeError } from "../../api/errors/api-entity-too-large.error";
import { ApiMessageResponseError } from "../../api/errors/api-message-response.error";
import { ApiUnauthorizedError } from "../../api/errors/api-unauthorized.error";
import { ApiUploadFileResponseError } from "../../api/errors/api-upload-file-response.error";
import { FileApi } from "../../api/services/file.service";
//components
import { NavBar } from '../../components/test/navbar.component';
import { Footer } from '../../components/test/footer.component';

export const UploadUserImage = () => {
  const [currentFile, setCurrentFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);
  const [message, setMessage] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const selectedFiles = files as FileList;
    if(selectedFiles) {
      setCurrentFile(selectedFiles[0]);
      setProgress(0);
    }
  };

  async function upload() {
    setProgress(0);
    if (!currentFile) return;

    try
    {
      const response = await FileApi.UploadUserThumbnailImageAsync(currentFile, (event: any) => {
        setProgress(Math.round((100 * event.loaded) / event.total));
      });
      
      if(response && response.url) {
        setMessage(response.url);
        setImageUrl(response.url);
      }
    } catch(error) {
      setProgress(0);

      if (error instanceof ApiMessageResponseError) {
        console.log("Validation Errors:");
        console.log(error.validationErrors);
        let msg = "";
        for (let i = 0; i < error.validationErrors.length; i++) {
          msg += error.validationErrors[i].message + " \n";
        }
        setMessage(msg);
      } else if (error instanceof ApiUnauthorizedError) {
        setMessage("user is not authorized." + "\n" + JSON.stringify(error));
      } else if (error instanceof ApiUploadFileResponseError) {
        if (error && error.error && error.error.errors && error.error.errors.file && error.error.errors.file[0]) {
          setMessage("Wrong type of file. You allowed to upload only images \n" + error.error.errors.file[0]);
        } else {
          setMessage("Wrong type of file. You allowed to upload only images \n"); 
        }
      } else if (error instanceof ApiEntityTooLargeError) {
        setMessage("File is too large.");
      } else if (error instanceof AxiosError) {
        setMessage("Server sider error." + "\n" + JSON.stringify(error));
      } else {
        setMessage("Ooops, something went wrong. :(" + "\n" + JSON.stringify(error));
        console.log(error);
      }

      setCurrentFile(undefined);
    }
  };

  return (
    <div className="home-body">
      <NavBar/>
      <Container>
      <div className='test-button-wrapper'>
        <div>
          <div className="row">
            <div className="col-8">
              <label className="btn btn-default p-0">
                <input type="file" onChange={selectFile} />
              </label>
            </div>

            <div className="col-4">
              <button
                className="btn btn-success btn-sm"
                disabled={!currentFile}
                onClick={upload}
              >
                Upload
              </button>
            </div>
          </div>

          {currentFile && (
            <div className="progress my-3">
              <div
                className="progress-bar progress-bar-info"
                role="progressbar"
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: progress + "%" }}
              >
                {progress}%
              </div>
            </div>
          )}

          {message && (
            <div className="alert alert-secondary mt-3" role="alert">
              {message}
            </div>
          )}
        </div>
      </div>

      <div className="result">
        <div>
          Image Result:
        </div>
        <div>
          <img src={imageUrl} />
        </div>
      </div>
    </Container>
    <Footer/>
    </div>
  );
};