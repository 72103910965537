import { AxiosError } from "axios";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { ApiMessageResponseError } from "../../api/errors/api-message-response.error";
import { ApiUnauthorizedError } from "../../api/errors/api-unauthorized.error";
import { UserApi } from "../../api/services/user.service";
//components
import { NavBar } from '../../components/test/navbar.component';
import { Footer } from '../../components/test/footer.component';

export const GetUserInfo = () => {
  const [message, setMessage] = useState<string>("");

  async function onGetUserInfoClick() {
    try {
      const user = await UserApi.GetUserByIdAsync("89A14BE8-AD7A-4F50-3F5D-08DAEE0154AD");
      setMessage(JSON.stringify(user));
    } catch(error) {
      if (error instanceof ApiMessageResponseError) {
        setMessage("Validation Errors:" + "\n" + JSON.stringify(error.validationErrors));
      } else if (error instanceof ApiUnauthorizedError) {
        setMessage("User is not authorized." + "\n" + JSON.stringify(error));
      } else if (error instanceof AxiosError) {
        setMessage("Server Side Error:" + "\n" + JSON.stringify(error));
      } else {
        setMessage("Something else:" + "\n" + JSON.stringify(error));
      }
    }
  }

  return (
    <div className="home-body">
      <NavBar />
      <Container>
      <div className='test-button-wrapper'>
          <button className='test-button' onClick={onGetUserInfoClick}>getUserInfo</button>
      </div>
      <div className="result">
        {message}
      </div>
    </Container>
      <Footer />
    </div>
  );
};