import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//Import all translation files
import translationEnglish from "./translation/English/translation.json";
import translationUkrainian from "./translation/Ukrainian/translation.json";
import translationRussian from "./translation/Russian/translation.json";

import userHomeEnglish from "./translation/English/userHome.json";
import userHomeUkrainian from "./translation/Ukrainian/userHome.json";
import userHomeRussian from "./translation/Russian/userHome.json";
//Import translation2 file
//Import translationEnglish2 from "./translation/English/translation2.json";

//---Using different namespaces
const resources = {
    en: {
        home: translationEnglish,
        userHome: userHomeEnglish,
    },
    uk: {
        home: translationUkrainian,
        userHome: userHomeUkrainian,
    },
    ru: {
        home: translationRussian,
        userHome: userHomeRussian,
    },
}

i18next.use(initReactI18next)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
  });

export default i18next;