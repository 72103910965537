import { ErrorResponse } from "../contracts/common/responses/error.contract";

export class ApiMessageResponseError extends Error {
  public validationErrors: Array<ErrorResponse> = new Array<ErrorResponse>();

  constructor(msg: string, errors: Array<ErrorResponse>) {
    super(msg);
    this.name = 'ApiMessageResponseError';
    this.message = msg;
    this.validationErrors = errors;
    
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ApiMessageResponseError.prototype);
  }
}