import { Button } from "@mui/material";


function CustomButton() {
  return (
    <Button
  sx={{
    background: "linear-gradient(45deg, #66bb6a, #43a047)",
    border: "none",
    borderRadius: 4,
    color: "#fff",
    cursor: "pointer",
    fontSize: 16,
    fontWeight: "bold",
    padding: "12px 24px",
    transition: "all 0.3s ease",
    "&:hover": {
      background: "linear-gradient(45deg, #6bd16f, #66bb6a)",
      boxShadow:
        "0 4px 6px rgba(0, 0, 0, 0.1), 0 8px 12px rgba(0, 0, 0, 0.1), 0 12px 18px rgba(0, 0, 0, 0.1)",
      transform: "scale(1.05)", 

    },
    "&:active": {
      transform: "scale(0.9)", 

    },
  }}
>
  Button
</Button>
  );
}

export default CustomButton