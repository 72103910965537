import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import './../../assets/scss/pages/login.page.scss'
import { UserApi } from "../../api/services/user.service";
import { ActivateUserRequest } from "../../api/contracts/user/requests/activate-user.contract";

const EmailConfirmationPage: React.FC = () =>{
  const [message, setMessage] = useState<string>("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  var isLoaded = false;

  useEffect(() => {
    const activateUserAsync = async () => {
      let code = searchParams.get("code");
      try {
        let activateUserRequest = new ActivateUserRequest();
        activateUserRequest.confirmationCode = code == null ? "" : code;
        await UserApi.activateUserAsync(activateUserRequest);
        navigate("/ihorTest/Authorization");
      } catch (error) {
        setMessage("Internal Server Error");
      }
    }

    if (!isLoaded) {
      isLoaded = true;
      activateUserAsync().catch(console.error);
    }
  });

  return (
    <>
      <p>Trying to activate your email...</p>
      <p>{message}</p>
    </>
  );
}

export default EmailConfirmationPage;