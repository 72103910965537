import { AxiosError } from "axios";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { ResendConfirmationCodeRequest } from "../../api/contracts/user/requests/resend-confirmation-code.contract";
import { ApiMessageResponseError } from "../../api/errors/api-message-response.error";
import { UserApi } from "../../api/services/user.service";
import { Formik, Field, Form, FormikHelpers } from "formik";
import TextField from "@mui/material/TextField";
import {useAppSelector, useAppDispatch} from "./../../app/hooks"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//components
import { NavBar } from '../../components/test/navbar.component';
import { Footer } from '../../components/test/footer.component';
import { registerUserAsync, selectTestRegistration } from "../../features/user/testRegisterSlice";
import { RegisterUserRequest } from "../../api/contracts/user/requests/register-user.contract";

type formValues = {
  name:string,
  email:string,
  password:string,
  passwordConfirmation:string
}

const initialValues: formValues = {
  name:'', 
  email:'', 
  password:'',
  passwordConfirmation: ''
}  

export const ResendConfirmationCode = () => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<string>("");
  const testRegistrationState = useAppSelector(selectTestRegistration);

  async function onResendConfirmationCodeClick() {
    let resendConfirmationCodeRequest = new ResendConfirmationCodeRequest();
    resendConfirmationCodeRequest.email = 'fear3171@gmail.com';

    try {
      const data = await UserApi.resendConfirmationCodeAsync(resendConfirmationCodeRequest);
      let date = new Date();
      if(data && data.nextAttemptDate) {
        date = new Date(Date.parse(data.nextAttemptDate));
      }

      setMessage("OK, email was sent ! Please, check your e-mail for confirmation code. DateToNextSend: " + date + " \n" + JSON.stringify(data));
    } catch(error) {
      if (error instanceof ApiMessageResponseError) {
        setMessage("Validation Errors:"+ '\n' + JSON.stringify(error.validationErrors));
      } else if (error instanceof AxiosError) {
        setMessage("Server Side Error:" + '\n' + JSON.stringify(error));
      } else {
        setMessage("Something else:" + '\n' + JSON.stringify(error));
        console.log(error);
      }
    }
  }

  const onRegisrationSubmit = async (data:formValues, {setSubmitting}: FormikHelpers<formValues>) => {
    setSubmitting(true);
    const currentRegistrationState = await dispatch(registerUserAsync(data));
    setSubmitting(false);
  }

  return (
    <div className="home-body">
    <NavBar/>
      <Container>
        <div className='test-button-wrapper'>
            <button className='test-button' onClick={onResendConfirmationCodeClick}>resendConfirmationCode</button>
        </div>
        <div className="result">
          {message}
        </div>

        <Formik 
          validateOnChange={true}
          onSubmit={onRegisrationSubmit}
          initialValues={initialValues}>
          {({errors, isSubmitting, touched})=>(
            <Form>
              <Field 
                margin="normal"
                required
                fullWidth
                placeholder="name"
                name="name"
                error={!!errors.email && touched.email}
                helperText={touched.email ? errors.email : ''}
                autoFocus
                color="warning" 
                as={TextField} />
              <Field 
                margin="normal"
                required
                fullWidth
                placeholder="Email"
                name="email"
                error={!!errors.email && touched.email}
                helperText={touched.email ? errors.email : ''}
                autoFocus
                color="warning" 
                as={TextField} />
              <Field 
                margin="normal"
                required
                fullWidth
                placeholder="password"
                name="password"
                error={!!errors.email && touched.email}
                helperText={touched.email ? errors.email : ''}
                autoFocus
                color="warning" 
                as={TextField} />
              <Field 
                margin="normal"
                required
                fullWidth
                placeholder="passwordConfirmation"
                name="passwordConfirmation"
                error={!!errors.email && touched.email}
                helperText={touched.email ? errors.email : ''}
                autoFocus
                color="warning" 
                as={TextField} />
                <Box sx={{display: 'flex', justifyContent:'center', mt: 3, mb: 2}}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting}
                    sx={{ width: '50%', backgroundColor:'#181818', '&:hover':{backgroundColor:'#fff', color:'#000'}}}>
                      Sign Up
                  </Button>
                </Box>
            </Form>
          )}
        </Formik>
        <div>
          <div>
            message:
          </div>
          <div>
            {testRegistrationState.message}
          </div>
          <div>
            status:
          </div>
          <div>
            {testRegistrationState.status}
          </div>
          <div>
            Messages:
          </div>
          <div>
            {testRegistrationState.validationErrors.map((m) => { return (
            <>
              <div>
                {m.message}
              </div>
            </>);})}
          </div>
          <div>
            response:
          </div>
          <div>
            {JSON.stringify(testRegistrationState.response)}
          </div>
        </div>
      </Container>
    <Footer/>
    </div>
  );
};