import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import './../../assets/scss/pages/login.page.scss'
import { RoomApi } from "../../api/services/room.service";

const RoomJoinPage: React.FC = () =>{
  const [message, setMessage] = useState<string>("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  var isLoaded = false;

  useEffect(() => {
    const attachUserToRoomAsync = async () => {
      let roomId = searchParams.get("roomId");
      let secretToken = searchParams.get("secretToken");
      if (roomId) {
        try {
          await RoomApi.JoinRoomAsync(roomId, secretToken);
          navigate(`/ihorTest/Room?roomid=${roomId}`);
        } catch (error) {
          setMessage("Internal Server Error");
        }
      } else {
        setMessage("Wrong RoomId.");
      }
    }

    if (!isLoaded) {
      isLoaded = true;
      attachUserToRoomAsync().catch(console.error);
    }
  });

  return (
    <>
      <p>Joining room...</p>
      <p>{message}</p>
    </>
  );
}

export default RoomJoinPage;