import { BaseService } from "./common/base.service";
import { AuthorizationRequest } from "../contracts/authorization/requests/authorization.contract";
import { AuthorizationResponse } from "../contracts/authorization/responses/authorization.contract";
import { RefreshTokenRequest } from "../contracts/authorization/requests/refresh-token.contract";
import { TokenManager } from "./token.service";

/**
 * Auth API abstraction layer communication via Axios (typescript singleton pattern)
 */
class AuthService extends BaseService {
  private static _instance: AuthService;
  private static _controller: string = 'token';

  private constructor(name: string) {
    super(name);
  }
  
  public static get Instance(): AuthService {
    return this._instance || (this._instance = new this(this._controller));
  }
  
  public async loginAsync(credentials: AuthorizationRequest): Promise<AuthorizationResponse> {
    const { data } = await this.$http.post<AuthorizationResponse>('generate', credentials);
    if(data != null && data.isAuthorized === true && data.isActive === true && data.token && data.refreshToken) {
      TokenManager.saveToken(data);
    }
    return data;
  }

  public async refreshToken(refreshTokenRequest: RefreshTokenRequest): Promise<AuthorizationResponse> {
    const { data } = await this.$http.post<AuthorizationResponse>('generate', refreshTokenRequest);
    if(data != null && data.isAuthorized === true && data.isActive === true && data.token && data.refreshToken) {
      TokenManager.saveToken(data);
    }
    return data;
  }
}
  
export const AuthApi = AuthService.Instance;