import React, { Ref, forwardRef, useEffect, useImperativeHandle } from "react";
import { PagedResponse } from "../../api/contracts/common/responses/pagedResponse.contract";
import { SearchMovieResponse } from "../../api/contracts/movie/responses/search-movie.contract";
import { MovieApi } from "../../api/services/movie.service";
import { SearchMovieRequest } from "../../api/contracts/movie/requests/search-movie.contract";

export interface SearchMovieComponentProps {
  name?: string | undefined;
}

export interface SearchMovieComponentRefObject {
  TogleOpen: () => void
}

export const SearchMovieComponent = forwardRef((props: SearchMovieComponentProps, ref: Ref<SearchMovieComponentRefObject>) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const [moviesData, setMoviesData] = React.useState<PagedResponse<SearchMovieResponse>>();
  const [currentPage, setCurrentPage] = React.useState<number>(0);

  useImperativeHandle(ref, () => ({ TogleOpen }));

  const TogleOpen = () => {
    if (!isOpened) {
      searchMovies();
    }
    setIsOpened(!isOpened);
  }

  useEffect(() => {
    searchMovies();
  }, [currentPage]);

  const searchMovies = async () => {
    let request = new SearchMovieRequest();
    request.page = currentPage;
    
    if (currentPage < 1) {
      request.page = 1;
      setCurrentPage(1);
    }

    let data = await MovieApi.SearchAsync(request);
    setMoviesData(data);
  }

  const onChangePage = (page: number) => {
    setCurrentPage(page);
  } 

  return (
    <>
      <div className={"search-movie-container " + (isOpened ? "search-movie-container-opened" : "") }>
        <div className="search-filters-wrapper">
          <div className="search-title">
            Search TextBox must be here
          </div>
        </div>
        <div className="movie-result-list-wrapper">
          <div className="movie-result-list">
            {moviesData != null && moviesData.items != null ? 
            <>
              {moviesData.items.map((movie, movieIndex) => { return (
                <div key={movieIndex} className="movie-result-list-item">
                  <img src={movie.additionalCoverUrl} />
                  <div className="movie-title">{movie.originalFullTitle ? movie.originalFullTitle : movie.shortTitle}</div>
                </div>
              );})}
            </>
            : <> I can't moveIt-moveIt anymore, BORIIIIIIS !</>}
          </div>
          <div className="paging-wrapper">
            <div className="paging-previous-btn paging-btn" onClick={() => onChangePage(currentPage - 1)}>
              {"<"}
            </div>
            <div className="paging-pager-btn-wrapper">
              {[...Array(moviesData?.totalPages).fill(0).map((_, i) => i).slice(currentPage < 8 ? 0 : currentPage-3, currentPage < 8 ? 10 : currentPage+7)].map((v, i) => { return (
                <div 
                className={"paging-pager-btn paging-btn" + (v+1 == currentPage ? " current-page" : "")} 
                key={v} 
                onClick={() => onChangePage(v+1)}>
                  {v+1}
                </div>);
              })}
            </div>
            <div className="paging-next-btn paging-btn" onClick={() => onChangePage(currentPage + 1)}>
              {">"}
            </div>
          </div>
        </div>
      </div>
    </>);
});