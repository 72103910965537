export class AuthorizationResponse {
    public isAuthorized?: boolean;
    public isActive?: boolean;
    public userId?: string;
    public userName?: string;
    public userEmail?: string;
    public token?: string;
    public tokenType?: string;
    public expiresInSeconds?: number;
    public expiresInUTC?: string;
    public issuedInUTC?: string;
    public refreshToken?: string;
    public refreshTokenExpiresInSeconds?: number;
    public refreshTokenExpiresInUTC?: string;
    public refreshTokenIssuedInUTC?: string;
    public message?: string;

    public static convert(input: any): AuthorizationResponse | null {
        const model: AuthorizationResponse = input as AuthorizationResponse;
        if(model.isAuthorized !== undefined) {
            return model;
        }
        return null;
    }
};
