import React from "react";
import {useEffect} from 'react';

//components
import { Footer } from '../../components/test/footer.component'; //test

import Navbar from '../../components/homePage/navbar.component';

export const UserHome = () => {
    //body background color
    useEffect(() => {
        document.body.classList.add('user-home-body');
        document.body.style.backgroundColor = '#ffffff';
    
        return () => {
          //optionally remove styles when component unmounts
          document.body.style.backgroundColor = '#ffffff';
          document.body.classList.remove('user-home-body');
        };}, []);

      return (
        <div className="home-body">
            <Navbar/>
            <Footer />
        </div>
      );
};
