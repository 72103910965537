import { EmailTimerResponse } from "../contracts/email/responses/email-timer.contract";
import { ActivateNewEmailRequest } from "../contracts/user/requests/activate-new-email.contract";
import { ActivateUserRequest } from "../contracts/user/requests/activate-user.contract";
import { ChangeUserPasswordRequest } from "../contracts/user/requests/change-user-password.contract";
import { ChangeUserRequest } from "../contracts/user/requests/change-user.contract";
import { ForgetPasswordConfirmationRequest } from "../contracts/user/requests/forget-password-confirmation.contract";
import { RegisterUserRequest } from "../contracts/user/requests/register-user.contract";
import { ResendConfirmationCodeRequest } from "../contracts/user/requests/resend-confirmation-code.contract";
import { SendChangeEmailConfirmationRequest } from "../contracts/user/requests/send-change-email-confirmation.contract";
import { SendForgetPasswordEmailRequest } from "../contracts/user/requests/send-forget-password-email.contract";
import { UserDetailsResponse } from "../contracts/user/responses/user-details.contract";
import { BaseService } from "./common/base.service";

class UserService extends BaseService {
  private static _instance: UserService;
  private static _controller: string = 'User';
  
  private constructor(name: string) {
    super(name);
  }
  
  public static get Instance(): UserService {
    return this._instance || (this._instance = new this(this._controller));
  }
  
  public async registerUserAsync(requestData: RegisterUserRequest): Promise<EmailTimerResponse> {
    const { data } = await this.postAsync<EmailTimerResponse>('', requestData);
    return data;
  }

  public async changeUserAsync(requestData: ChangeUserRequest) {
    await this.putAsync('', requestData);
  }

  public async changeUserPasswordAsync(requestData: ChangeUserPasswordRequest) {
    await this.putAsync('ChangePassword', requestData);
  }

  public async resendConfirmationCodeAsync(requestData: ResendConfirmationCodeRequest): Promise<EmailTimerResponse> {
    const { data } = await this.putAsync<EmailTimerResponse>('ResendConfirmationCode', requestData);
    return data;
  }

  public async activateUserAsync(requestData: ActivateUserRequest) {
    await this.putAsync('Activate', requestData);
  }

  public async sendForgetPasswordEmailAsync(requestData: SendForgetPasswordEmailRequest): Promise<EmailTimerResponse> {
    const { data } = await this.putAsync<EmailTimerResponse>('SendForgetPasswordEmail', requestData);
    return data;
  }

  public async ForgetPasswordConfirmationAsync(requestData: ForgetPasswordConfirmationRequest) {
    await this.putAsync('ForgetPassword', requestData);
  }

  public async SendChangeEmailConfirmationAsync(requestData: SendChangeEmailConfirmationRequest): Promise<EmailTimerResponse> {
    const { data } = await this.putAsync<EmailTimerResponse>('SendChangeEmailConfirmation', requestData);
    return data;
  }

  public async ActivateNewEmailAsync(requestData: ActivateNewEmailRequest) {
    await this.putAsync('ActivateNewEmail', requestData);
  }

  public async GetUserByIdAsync(userId: string): Promise<UserDetailsResponse> {
    const { data } = await this.getAsync<UserDetailsResponse>(userId);
    return data;
  }
}

export const UserApi = UserService.Instance;