import { PagedResponse } from '../../api/contracts/common/responses/pagedResponse.contract';
import { RoomResponse } from '../../api/contracts/room/responses/room.contract';
import './../../assets/scss/pages/ihortest.pages.scss'

export interface RoomListGridComponentProps {
    roomResponse: PagedResponse<RoomResponse> | undefined;
    onBtnClicked?: (id: string) => void;
    buttonTitle: string;
    onOpenRoom?: (id: string) => void;
    onChangeRoomsPage: (orderNumber: number) => void;

    title: string;
    noItemsMessage: string;
}

export const RoomListGridComponent = (props : RoomListGridComponentProps) => {
 
  return (
    <>
      <div className="grid-container">
        <div className="grid-title">
          {props.title}
        </div>
        
        <div className="grid-item-list-container">
          { props.roomResponse == null 
          || (props.roomResponse != null && (props.roomResponse.items == null || props.roomResponse.totalItems == null || props.roomResponse.totalItems == 0)) ?
            <>
              {props.noItemsMessage}
            </>
          :
            <>
              {props.roomResponse.items?.map((r, i) => { return (
                <div className="grid-item-container" key={r.id}>
                  <hr />
                  <div className="grid-item-column">
                    #{(i+1).toString()}
                  </div>
                  <div className="grid-item-column">
                    {r.name}
                  </div>
                  {props.onBtnClicked ?
                    <div className="grid-item-btn" onClick={() => props.onBtnClicked ? props.onBtnClicked(r.id == null ? "" : r.id) : null}>
                      {props.buttonTitle}
                    </div>
                  :
                    <></>
                  }
                  {props.onOpenRoom ?
                    <div className="grid-item-btn" onClick={() => props.onOpenRoom ? props.onOpenRoom(r.id == null ? "" : r.id) : null}>
                      OPEN
                    </div>
                  :
                    <></>
                  }
                  <hr />
                </div>);
              })}
            </>
          }
        </div>

        <div className="paging-wrapper">
          <div className="paging-previous-btn paging-btn" onClick={() => props.onChangeRoomsPage((props.roomResponse?.currentPage ? props.roomResponse?.currentPage : 0) - 1)}>
            {"<"}
          </div>
          <div className="paging-pager-btn-wrapper">
            {[...Array(props.roomResponse?.totalPages).fill(0)].map((v, i) => { return (
              <div 
              className={"paging-pager-btn paging-btn" + (i+1 == props.roomResponse?.currentPage ? " current-page" : "")} 
              key={i} 
              onClick={() => props.onChangeRoomsPage(i+1)}>
                {i+1}
              </div>);
            })}
          </div>
          <div className="paging-next-btn paging-btn" onClick={() => props.onChangeRoomsPage((props.roomResponse?.currentPage ? props.roomResponse?.currentPage : 0) + 1)}>
            {">"}
          </div>
        </div>
      </div>
    </>
  );
};