import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EmailTimerResponse } from "../../api/contracts/email/responses/email-timer.contract";
import { UserApi } from "../../api/services/user.service";
import { RegisterUserRequest } from "../../api/contracts/user/requests/register-user.contract";
import { StateStatus } from "../common/state-status.enum";
import { BaseThunkModel } from "../common/baseThunk.model";
import { RootState } from "../../app/store";
import { BaseState } from "../common/base.state";
import { BaseStateHelper } from "../common/base-state.helper";

type RegistrationState = {
	registrationResponse?: EmailTimerResponse | null
}

const initialState: BaseState<RegistrationState> = {
	response: {
    registrationResponse: null
  },
  validationErrors: [],
	status: StateStatus.notInitialized,
	message: null
}

class RegistrationResponseMessage extends BaseThunkModel {
  public response?: EmailTimerResponse | null = null;
}

export const registerUserAsync = createAsyncThunk(
  'testregister/fetchRegister',
  async (data: RegisterUserRequest): Promise<RegistrationResponseMessage> => {
    const result: RegistrationResponseMessage = new RegistrationResponseMessage();

    try {
      const response = await UserApi.registerUserAsync(data);
      result.response = response;
      result.isOk = true;
      return result;
    } catch (error) {
      result.catchError(error);
    }

    return result;
  }
)

const testRegisterSlice = createSlice({
  name: 'testregister',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUserAsync.pending, (state) => {
        state.status = StateStatus.loading;
      })
      .addCase(registerUserAsync.fulfilled, (state, action) => {
        BaseStateHelper.configureState(state, action.payload);

        if (action.payload != null) {
          if (action.payload.isOk) {
            if(state.response) {
              state.response.registrationResponse = action.payload.response;
            }
          }
        }
      })
      .addCase(registerUserAsync.rejected, (state, action) => {
        state.message = "Server Internal Error.";
        state.status = StateStatus.error;
      });
  }
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTestRegistration = (state: RootState) => state.testRegistration;

export default testRegisterSlice.reducer;