import { EmailTimerResponse } from "../contracts/email/responses/email-timer.contract";
import { ScriptProgressResponse } from "../contracts/server/response/script-progress.contract";
import { ActivateNewEmailRequest } from "../contracts/user/requests/activate-new-email.contract";
import { ActivateUserRequest } from "../contracts/user/requests/activate-user.contract";
import { ChangeUserPasswordRequest } from "../contracts/user/requests/change-user-password.contract";
import { ChangeUserRequest } from "../contracts/user/requests/change-user.contract";
import { ForgetPasswordConfirmationRequest } from "../contracts/user/requests/forget-password-confirmation.contract";
import { RegisterUserRequest } from "../contracts/user/requests/register-user.contract";
import { ResendConfirmationCodeRequest } from "../contracts/user/requests/resend-confirmation-code.contract";
import { SendChangeEmailConfirmationRequest } from "../contracts/user/requests/send-change-email-confirmation.contract";
import { SendForgetPasswordEmailRequest } from "../contracts/user/requests/send-forget-password-email.contract";
import { UserDetailsResponse } from "../contracts/user/responses/user-details.contract";
import { BaseService } from "./common/base.service";

class ServerService extends BaseService {
  private static _instance: ServerService;
  private static _controller: string = 'Server';
  
  private constructor(name: string) {
    super(name);
  }
  
  public static get Instance(): ServerService {
    return this._instance || (this._instance = new this(this._controller));
  }

  public async getScriptProgressAsync(): Promise<ScriptProgressResponse> {
    const { data } = await this.getAsync<ScriptProgressResponse>('');
    return data;
  }
}

export const ServerApi = ServerService.Instance;