import React from "react";
import '../../assets/scss/components/footer.component.scss';
import { Row, Image, Container } from "react-bootstrap";

export const Footer = () => {
  return (
    <Container>
      <Row className="social-media justify-content-md-center">
        <a className="social-media-link" href="www.linkedin.com">
          <div className="image-wrapper icon-image">
            <Image src="https://cdn-icons-png.flaticon.com/512/174/174857.png"/>
          </div>
        </a>

        <a className="social-media-link" href="www.Github.com">
          <div className="image-wrapper icon-image">
            <Image src="https://cdn-icons-png.flaticon.com/512/25/25231.png"/>
          </div>
        </a>

        <a className="social-media-link" href="www.Twitter.com">
          <div className="image-wrapper icon-image">
            <Image src="https://holyspiritfremont.org/wp-content/uploads/2020/10/twitter-logo.png"/>
          </div>
        </a>
      </Row>
    </Container>
  );
};