import * as React from 'react';
import MainToolBar from './appbar.component';
import FilmCard from "./card.component";
import BannerCard from "./banner.component";

import '../../assets/scss/components/menu.component.scss';
import { TokenManager } from "../../api/services/token.service";
import { ServerApi } from '../../api/services/server.service';
import { ScriptProgressResponse } from '../../api/contracts/server/response/script-progress.contract';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { styled, Theme, CSSObject} from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import LinearProgress from "@mui/material/LinearProgress";

import LanguageIcon from '@mui/icons-material/Language';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ExploreOutlinedIcon from '@mui/icons-material/ExploreOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';


const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Navbar() {

  const [open, setOpen] = React.useState(true);
  const [isLoadingStatistics, setIsLoadingStatistics] = React.useState(false);
  const [statistics, setStatistics] = React.useState(new ScriptProgressResponse());

  useEffect(() => {
    loadStatistics();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerOpenForLang = () => {
    setOpen(true);
  };

  const [openLang, setOpenLang] = React.useState(false);

  const handleOpenLang = () => {
    setOpenLang(!openLang);
  };

  const handleOpenLangClose = () => {
    setOpenLang(false);
  };

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  function isUserAuthorized(): boolean {
    const token = TokenManager.getToken();
    if(token && token.token && token.refreshToken) {
        return true;
    }
    return false;
  }

  const { t, i18n } = useTranslation(['userHome']);

  const onClickLanguageChange = (language: string) => {
      i18n.changeLanguage(language);
  }

  const loadStatistics = async () => {
    if(isLoadingStatistics === false) {
      setIsLoadingStatistics(true);
      const data = await ServerApi.getScriptProgressAsync();
      setStatistics(data);
      setIsLoadingStatistics(false);
    }
  }

  const onClickLoadStatistics = async (e: React.MouseEvent<HTMLButtonElement>) => {
    loadStatistics();
  }

  const getPercentage = (currentNum: number | undefined, maxNum: number | undefined): number => {
      return ((currentNum ?? 1) * 100)/(maxNum ?? 1);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <MainToolBar />
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <a href='/'>
          <Typography variant="h6" noWrap component="div">
            PLACEHOLDER
          </Typography>
          </a>
        </DrawerHeader>
        <Divider />
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="Menu-list-subheader"
          subheader={ open ? <ListSubheader component="div" id="Menu-list-subheader">
          {t("Menu", {ns: ['userHome']})}
            </ListSubheader>: null
          }
        > 
          <ListItemButton
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
          >
            <ListItemIcon>
              <AddCircleOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
              {t("CreateRoom", {ns: ['userHome']})}
              </ListItemText>
          </ListItemButton>

          <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
          >
            <ListItemIcon>
              <GridViewOutlinedIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
              {t("YourRooms", {ns: ['userHome']})}
              </ListItemText>
          </ListItemButton>

          <ListItemButton
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
          >
            <ListItemIcon>
              <ExploreOutlinedIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
              {t("Catalog", {ns: ['userHome']})}
            </ListItemText>
          </ListItemButton>

          <ListItemButton
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
          >
            <ListItemIcon>
              <RuleOutlinedIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
              {t("WatchList", {ns: ['userHome']})}
              </ListItemText>
          </ListItemButton>
        </List>

        {open ? <Divider /> : null}

        {isUserAuthorized() ? 
        <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="Friends-list-subheader"
        subheader={ open ? <ListSubheader component="div" id="Friends-list-subheader">
            {t("Friends", {ns: ['userHome']})}
          </ListSubheader> : null
        }
        >
        </List>
        : null }

        {open ? <Divider /> : null}

        <List 
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="Settings-list-subheader"
          subheader={ open ? <ListSubheader component="div" id="Settings-list-subheader">
              {t("More", {ns: ['userHome']})}
            </ListSubheader> : null
          }
        >
          <ListItemButton onClick={()=>{handleOpenLang(); handleDrawerOpenForLang()}}>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
              {t("Language", {ns: ['userHome']})}
            </ListItemText>
            {openLang ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          </ListItemButton>
          <Collapse in={openLang} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton onClick={()=>{handleOpenLang(); onClickLanguageChange("en")}} sx={{ pl: 4 }}>
                <ListItemText primary="English" />
              </ListItemButton>

              <ListItemButton onClick={()=>{handleOpenLang(); onClickLanguageChange("uk")}} sx={{ pl: 4 }}>
                <ListItemText primary="Українська" />
              </ListItemButton>

              <ListItemButton onClick={()=>{handleOpenLang(); onClickLanguageChange("ru")}} sx={{ pl: 4 }}>
                <ListItemText primary="Русский" />
              </ListItemButton>
            </List>
          </Collapse>
          
          <ListItemButton onClick={()=> {handleOpenLangClose(); handleDrawerOpen()}}>
            <ListItemIcon>
              {open ? <ArrowCircleLeftOutlinedIcon /> :  <ArrowCircleRightOutlinedIcon />}
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
              {t("Collapse", {ns: ['userHome']})}
            </ListItemText>
          </ListItemButton>


          <ListItemButton
            selected={selectedIndex === 4}
            onClick={(event) => handleListItemClick(event, 4)}
          >
            <ListItemIcon>
              <SettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
              {t("Settings", {ns: ['userHome']})}
            </ListItemText>
          </ListItemButton>
        </List>

        {open ? <Divider /> : null}

          {isUserAuthorized() ?
            <List>
              <ListItemButton sx={{ bgcolor: "rgba(241, 60, 64,1) "}}>
                <ListItemIcon>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                  {t("Sign out", {ns: ['userHome']})}
                </ListItemText>
              </ListItemButton>
            </List>
          : 
            <List>
              <ListItemButton sx={{ bgcolor: "rgba(94, 251, 64, 1)" }}>
                <ListItemIcon>
                  <LoginOutlinedIcon />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                  {t("Sign in", {ns: ['userHome']})}
                </ListItemText>
              </ListItemButton>

              <ListItemButton sx={{ bgcolor: "rgba(94, 251, 64, 1)" }}>
                <ListItemIcon>
                  <PersonAddAltOutlinedIcon />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                  {t("Register", {ns: ['userHome']})}
                </ListItemText>
              </ListItemButton>
            </List>
          }

        {open ? <Divider /> : null}

      </Drawer>



      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box>
          <Box>
            <BannerCard
            />
          </Box>
          <Box>
            <FilmCard
              title="Inception"
              rating={8.8}
              onCreateRoomClick={() => console.log('Create room')}
              onWatchClick={() => console.log('Watch movie')}
            />
          </Box>
        </Box>
        <Typography paragraph color="green">
            We fetched {statistics.numOfFilmsInserted} Films
          </Typography>
          <Box width="250px" margin="auto" marginBottom="20px">
            {statistics.isScriptRunning ?
            <>
              <Alert severity="success">Script is Running.</Alert>
            </>
            :
            <>
              <Alert severity="error">Script is NOT Running.</Alert>
            </>
            }
          </Box>
          <Typography paragraph color="green">
            Hacking Rezka...
          </Typography>
          { isLoadingStatistics ?
            <>
            <CircularProgress color="success" />
            </>
            : ""}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" color="error" value={statistics ? getPercentage(statistics.currentPage, statistics.maxPages) : 0} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="green">{`${Math.round(statistics ? getPercentage(statistics.currentPage, statistics.maxPages) : 0)}%`}</Typography>
            </Box>
          </Box>
          <Box>
            <Button variant="outlined" color="success" onClick={onClickLoadStatistics}>Refresh Statisctic</Button>
          </Box>
      </Box>
    </Box>
  );
}
