import { Navbar, Container, Nav, NavDropdown} from "react-bootstrap";
import { LinkContainer } from 'react-router-bootstrap';
import { TokenManager } from "../../api/services/token.service";

import { useTranslation } from "react-i18next";


export const NavBar = () => {
  
  function isUserAuthorized(): boolean {
    const token = TokenManager.getToken();
    if(token && token.token && token.refreshToken) {
      return true;
    }
    return false;
  }

  const { t, i18n } = useTranslation(['home']);

    const onClickLanguageChange = (language: string) => {
        i18n.changeLanguage(language);
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>React-Bootstrap</Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link> {t("Home", {ns: ['home']})}</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/Login">
              <Nav.Link>{t("Login", {ns: ['home']})}</Nav.Link>
            </LinkContainer>
            { isUserAuthorized() ?
            <>
              <LinkContainer to="/resendConfirmationCode">
                <Nav.Link>{t("ResendConfirmationCode", {ns: ['home']})}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/getUserToken">
                <Nav.Link>{t("GetUserToken", {ns: ['home']})}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/getJoinedRooms">
                <Nav.Link>{t("GetJoinedRooms", {ns: ['home']})}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/getUserInfo">
                <Nav.Link>{t("GetUserInfo", {ns: ['home']})}</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/uploadUserImage">
                <Nav.Link>{t("UploadUserImage", {ns: ['home']})}</Nav.Link>
              </LinkContainer>
            </>
            : "" 
            }
          </Nav>
          <Nav className="me-auto">
            <LinkContainer to="/userHome">
              <Nav.Link> {t("UserHome", {ns: ['home']})}</Nav.Link>
            </LinkContainer>
            <NavDropdown title= {t("Language", {ns: ['home']})} id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={()=>onClickLanguageChange("en")}>English</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>onClickLanguageChange("uk")}>Українська</NavDropdown.Item>
              <NavDropdown.Item onClick={()=>onClickLanguageChange("ru")}>Русский</NavDropdown.Item> 
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};