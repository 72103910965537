import React from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import { Formik, Field, Form, FormikHelpers } from "formik";
import Typography from "@mui/material/Typography";
import {makeStyles} from "tss-react/mui";
import {registerUserAsync, selectAuthorization} from "./../../features/user/authSlice";
import {useAppSelector, useAppDispatch} from "./../../app/hooks"
import leftSideGirl from './../../assets/images/login/T1.png';
import loginLogo from './../../assets/images/login/Roxy.jpg';
import './../../assets/scss/pages/login.page.scss';

type formValues = {
    username: string,
    email:string,
    password:string,
    passwordConfirm:string,
  }
  
  const useStyles = makeStyles()({
    'form_field_input' : {
      '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
        backgroundColor: '#fff'
      }
    }
  });


const Registration:React.FC = () => {
    const dispatch = useAppDispatch();
    const {classes} = useStyles();


    const initialValues: formValues = {
        username:'',
        email:'', 
        password:'', 
        passwordConfirm:''
      }

    const validate = ({username, email, password, passwordConfirm}:formValues) => {
      const errors: Record<string, string> = {};

      if (!username) {
        errors.username = 'Username is required field';
      } else if (username.length < 3) {
        errors.username = 'Username is too short';
      }
      else if (username.length > 20) {
        errors.username = 'Username is too long';
      }

      if (!email) {
        errors.email = 'Email is required field';
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = 'Invalid email address';
      }

      if (!password) {
        errors.password = 'Password is required field';
      } else if (password.length < 8) {
        errors.password = 'Password is too short';
      } else if (password.length > 20) {
        errors.password = 'Password is too long';
      } else if (!/[a-z]/.test(password)) {
        errors.password = 'Password should contain lower case';
      } else if (!/[A-Z]/.test(password)) {
        errors.password = 'Password should contain upper case';
      } else if (!/[0-9]/.test(password)) {
        errors.password = 'Password should contain a number';
      }

      if (!passwordConfirm) {
        errors.passwordConfirm = 'Please confirm the password'
      }

      return errors;
    }

    const onSubmit = async (data:formValues, {setSubmitting}: FormikHelpers<formValues>) => {
        setSubmitting(true);
        const registrationState = await dispatch(registerUserAsync(data));
        setSubmitting(false);
    }
    return (
        <>
      <Grid container component="main" className="login-page-container">
        <Grid container component="main" className="login-page-container-light">
          <Box component='img' src={leftSideGirl} sx={{position:'fixed', width: '370px', top:'calc(100vh - 370px)', left:'-40px'}}/>
          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            sx={{
              backgroundImage: 'url(' + loginLogo + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid sx={{background: "transparent" }} item xs={12} sm={8} md={6} component={Paper} elevation={6} display={"flex"} justifyContent={"center"} alignItems={"center"} square> 
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" color={"#fff"}>
                Sign Up
              </Typography>
              <Formik 
                validateOnChange={true}
                initialValues={initialValues}
                validate={validate}
                onSubmit={onSubmit}>
                {({errors, isSubmitting, touched})=>(
                  <Form>
                    <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Username"
                      name="username"
                      error={!!errors.username && touched.username}
                      helperText={touched.username ? errors.username : ''}
                      autoFocus
                      color="warning" 
                      as={TextField}
                      className={classes.form_field_input} />
                    <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Email"
                      name="email"
                      error={!!errors.email && touched.email}
                      helperText={touched.email ? errors.email : ''}
                      color="warning" 
                      as={TextField}
                      className={classes.form_field_input} />
                    <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Password"
                      name="password"
                      error={!!errors.password  && touched.password}
                      helperText={touched.password ? errors.password : ''}
                      color="warning" 
                      as={TextField} 
                      className={classes.form_field_input}/>
                      <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Confirm the password"
                      name="passwordConfirm"
                      error={!!errors.passwordConfirm  && touched.passwordConfirm}
                      helperText={touched.passwordConfirm ? errors.passwordConfirm : ''}
                      color="warning" 
                      as={TextField} 
                      className={classes.form_field_input}/>
                    <Box sx={{display: 'flex', justifyContent:'center', mt: 3, mb: 2}}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        sx={{ width: '50%', backgroundColor:'#181818', '&:hover':{backgroundColor:'#fff', color:'#000'}}}>
                          Sign Up
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
    )
}

export default Registration;