import { BaseState } from "./base.state";
import { BaseThunkModel } from "./baseThunk.model";
import { StateStatus } from "./state-status.enum";

export class BaseStateHelper {
  public static configureState<T>(state: BaseState<T>, payload: BaseThunkModel) {
    if (payload != null) {
      if (payload.isOk) {
        state.status = StateStatus.successfull;
      } else {
        if (payload.validationErrors && payload.validationErrors.length > 0) {
          state.validationErrors = payload.validationErrors;
          state.status = StateStatus.validationError;
        } else {
          state.message = payload.errorMessage;
          state.status = StateStatus.error;
        }
      }
    } else {
      state.message = "Server Internal Error.";
      state.status = StateStatus.error;
    }
  }
}