import React, { useState } from "react";
import { AxiosError } from "axios";

import { Container } from "react-bootstrap";

import { ApiMessageResponseError } from "../../api/errors/api-message-response.error";
import { ApiUnauthorizedError } from "../../api/errors/api-unauthorized.error";
import { RoomApi } from "../../api/services/room.service";
//components
import { NavBar } from '../../components/test/navbar.component';
import { Footer } from '../../components/test/footer.component';

export const GetJoinedRooms = () => {
  const [message, setMessage] = useState<string>("");

  async function onGetPublicRoomsClick() {
    try {
      const rooms = await RoomApi.GetJoinedRoomsAsync(1, 10);
      setMessage(JSON.stringify(rooms));
    } catch(error) {
      if (error instanceof ApiMessageResponseError) {
        setMessage("Validation Errors:" + "\n" + JSON.stringify(error.validationErrors));
      } else if (error instanceof ApiUnauthorizedError) {
        setMessage("User is not authorized." + "\n" + JSON.stringify(error));
      } else if (error instanceof AxiosError) {
        setMessage("Server Side Error:" + "\n" + JSON.stringify(error));
      } else {
        setMessage("Something else:" + "\n" + JSON.stringify(error));
      }
    }
  }

  return (
    <div className="home-body">
    <NavBar/>
    <Container>
      <div className='test-button-wrapper'>
          <button className='test-button' onClick={onGetPublicRoomsClick}>getJoinedRooms</button>
      </div>
      <div className="result">
        {message}
      </div>
    </Container>
    <Footer/>
    </div>
  );
};