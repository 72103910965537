import React from "react";
import { Container } from "react-bootstrap";
import { Counter } from "../../features/counter/Counter";
import logo from '../../assets/images/react-logo.svg';

//components
import { NavBar } from '../../components/test/navbar.component';
import { Footer } from '../../components/test/footer.component';

export const Home = () => {
  return (
    <div className="home-body">
      <NavBar />
        <Container>
          <img src={logo} className="App-logo" alt="logo" />
          <Counter />
          <p>
            Edit <code>src/App.tsx</code> and save to reload.
          </p>
          <span>
            <span>Learn </span>
            <a
              className="App-link"
              href="https://reactjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React
            </a>
            <span>, </span>
            <a
              className="App-link"
              href="https://redux.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Redux
            </a>
            <span>, </span>
            <a
              className="App-link"
              href="https://redux-toolkit.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Redux Toolkit
            </a>
            ,<span> and </span>
            <a
              className="App-link"
              href="https://react-redux.js.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              React Redux
            </a>
          </span>
        </Container>
      <Footer />
    </div>
  );
};