import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Formik, Field, Form, FormikHelpers } from "formik";
import Typography from "@mui/material/Typography";
import {makeStyles} from "tss-react/mui";
import {registerUserAsync, selectAuthorization} from "../../features/user/authSlice";
import {useAppSelector, useAppDispatch} from "../../app/hooks"
import leftSideGirl from './../../assets/images/login/T1.png';
import loginLogo from './../../assets/images/login/Roxy.jpg';
import './../../assets/scss/pages/login.page.scss'
import { EmailTimerResponse } from "../../api/contracts/email/responses/email-timer.contract";
import { ResponseModel } from "../../features/models/ResponseModel";

type formValues = {
  name:string,
  email:string,
  password:string,
  passwordConfirmation:string
}

const useStyles = makeStyles()({
  'form_field_input' : {
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      backgroundColor: '#fff'
    }
  }
});

const RegistrationPage: React.FC = () =>{
  const dispatch = useAppDispatch();
  const {classes} = useStyles();
  const registrationState = useAppSelector(selectAuthorization);
  const [registrationMessage, setRegistrationMessage] = useState<string>("");
  const [emailTimerMessage, setEmailTimerMessage] = useState<string>("");

  const initialValues: formValues = {
    name:'',
    email:'', 
    password:'', 
    passwordConfirmation:''
  }  

  const validate = ({name, email, password, passwordConfirmation}: formValues)=>{
    const errors: Record<string, string> = {};
  
    if (!name) {
      errors.name = 'Name is required';
    } else if (!/^[A-Za-z0-9]+([A-Za-z0-9]*|[._-]?[A-Za-z0-9]+)*$/i.test(name)) {
      errors.name = 'Invalid Name';
    } else if (name.length < 3) {
      errors.name = 'Name is too short';
    } else if (name.length > 20) {
      errors.name = 'Name is too long';
    }

    if (!email) {
      errors.email = 'Email is required field';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test( email)) {
      errors.email = 'Invalid email address';
    }
    
    if (!password) {
      errors.password = 'Password is required field';
    } else if (password.length < 8) {
      errors.password = 'Password is too short';
    } else if (password.length > 20) {
      errors.password = 'Password is too long';
    } else if (!/[a-z]/.test(password)) {
      errors.password = 'Password should contain lower case';
    } else if (!/[A-Z]/.test(password)) {
      errors.password = 'Password should contain upper case';
    } else if (!/[0-9]/.test(password)) {
      errors.password = 'Password should contain a number';
    } else if (password != passwordConfirmation) {
      errors.password = 'Fields Password and Pasword Confirmation are not equal'
      errors.passwordConfirmation = 'Fields Password and Pasword Confirmation are not equal'
    }

    return errors;
  }
  
  const onSubmit = async (data:formValues, {setSubmitting}: FormikHelpers<formValues>) => {
    setSubmitting(true);
    const currentRegistrationState = await dispatch(registerUserAsync(data));
    if (currentRegistrationState != null && currentRegistrationState.payload != null) {
      let registrationPayload = currentRegistrationState.payload as ResponseModel;
      if (registrationPayload.isOk && registrationPayload.response) {
        setRegistrationMessage("You're registrated. Confirmation URL sent to your Email. Please, check your inbox and follow the link in Confirmation Email to Confirm your Account.");
        
        let timerMessage = "it's your ";

        let emailTimer = registrationPayload.response as EmailTimerResponse;
        if (emailTimer.attemtNumber) {
          timerMessage += emailTimer.attemtNumber.toString();
        }
        
        timerMessage += " Confrimation email and you can send new confirmation after ";

        let nextAttemptDate = emailTimer.getNextAttemptDate();
        if (nextAttemptDate) {
          timerMessage += nextAttemptDate.toString();
        }
        
        setEmailTimerMessage(timerMessage);
      } else {
        setRegistrationMessage(registrationPayload.message == null ? '' : registrationPayload.message);
        setEmailTimerMessage("");
      }
    }
    setSubmitting(false);
  }

  return (
    <>
      <Grid container component="main" className="login-page-container">
        <Grid container component="main" className="login-page-container-light">
          <Box component='img' src={leftSideGirl} sx={{position:'fixed', width: '370px', top:'calc(100vh - 370px)', left:'-40px'}}/>
          <Grid
            item
            xs={false}
            sm={4}
            md={6}
            sx={{
              backgroundImage: 'url(' + loginLogo + ')',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <Grid sx={{background: "transparent" }} item xs={12} sm={8} md={6} component={Paper} elevation={6} display={"flex"} justifyContent={"center"} alignItems={"center"} square> 
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5" color={"#fff"}>
                Sign Up
              </Typography>
              <Formik 
                validateOnChange={true}
                initialValues={initialValues}
                validate={validate}
                onSubmit={onSubmit}>
                {({errors, isSubmitting, touched})=>(
                  <Form>
                    <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Name"
                      name="name"
                      autoFocus
                      color="warning" 
                      as={TextField}
                      className={classes.form_field_input} />
                    <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Email"
                      name="email"
                      error={!!errors.email && touched.email}
                      helperText={touched.email ? errors.email : ''}
                      autoFocus
                      color="warning" 
                      as={TextField}
                      className={classes.form_field_input} />
                    <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Password"
                      name="password"
                      error={!!errors.password  && touched.password}
                      helperText={touched.password ? errors.password : ''}
                      color="warning" 
                      as={TextField} 
                      className={classes.form_field_input}/>
                    <Field 
                      margin="normal"
                      required
                      fullWidth
                      placeholder="Password Confirmation"
                      name="passwordConfirmation"
                      error={!!errors.passwordConfirmation  && touched.passwordConfirmation}
                      helperText={touched.passwordConfirmation ? errors.passwordConfirmation : ''}
                      color="warning" 
                      as={TextField} 
                      className={classes.form_field_input}/>

                    <Typography component="p" color={"#fff"}>
                        {registrationMessage}
                    </Typography>

                    <Box sx={{display: 'flex', justifyContent:'center', mt: 3, mb: 2}}>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                        sx={{ width: '50%', backgroundColor:'#181818', '&:hover':{backgroundColor:'#fff', color:'#000'}}}>
                          Sign Up
                      </Button>
                    </Box>

                    <Typography component="p" color={"#fff"}>
                        {emailTimerMessage}
                    </Typography>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default RegistrationPage;