import './../../assets/scss/pages/login.page.scss'
import Box from "@mui/material/Box";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import smileOn from './../../assets/images/smileOn.png';
import smileOff from './../../assets/images/smileOff.png';
import './../../assets/scss/pages/login.page.scss'
import Button from "@mui/material/Button";


const RoomPage: React.FC = () => {
  const [isSmile, setIsSmile] = useState<boolean>(false);

  return (
    <>
      <Grid container component="main" className="login-page-container">
        <Grid container component="main" className="login-page-container-light">
          <Box component='img' src={isSmile ? smileOn : smileOff} sx={{ width: '100%', height: 'auto' }} />
          <Button
            type="submit"
            variant="contained"
            sx={{ position: 'fixed', width: '100%', backgroundColor: '#24434d', '&:hover': { backgroundColor: '#fff', color: '#000' } }}
            onClick={() => {
              setIsSmile(!isSmile);
            }}>
            Smile
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default RoomPage;