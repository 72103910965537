export class ErrorResponse {
  public message?: string;
  public dateTime?: string;

  public static convertArr(input: any): ErrorResponse[] | null {
      const model: ErrorResponse[] = input as ErrorResponse[];
      if(model != null && model.length > 0 && model[0].message !== undefined) {
          return model;
      }
      return null;
  }

  public static isErrorResponseArr(input: any): input is ErrorResponse[] {
    const model: ErrorResponse[] = input as ErrorResponse[];
    if(model != null && model.length > 0 && model[0].message !== undefined) {
        return true;
    }
    return false;
}
};